var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"name","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-2",attrs:{"flat":""}},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","sm":"10"}},[_c('v-toolbar-title',{staticClass:"error--text text-uppercase font-weight-bold text--darken-1 justify-center"},[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"color":"error"}},[_vm._v("mdi-domain")]),_vm._v("Business Types ")],1),_c('p',{staticClass:"grey--text ml-8 mt-n2"},[_vm._v("List of all business types")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","align":"right"}},[_c('v-dialog',{attrs:{"max-width":"550px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 text-capitalize",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" New ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline error--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","dense":""}},[_c('v-text-field',{attrs:{"label":"Title","rules":_vm.requiredRules,"color":"grey","dense":"","outlined":""},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{staticClass:"my-n5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"Description","color":"grey","dense":"","rules":_vm.requiredRules},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize mx-1",attrs:{"color":"error","dark":""},on:{"click":_vm.close}},[_vm._v(" Cancel "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cancel")])],1),_c('v-btn',{staticClass:"text-capitalize mx-1",attrs:{"color":"primary","dark":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Submit "),_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)],1)],1)],1)],1)],1),_c('v-spacer')],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-divider')]},proxy:true},{key:"item.closing_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateDefault(item.closing_date))+" ")]}},{key:"item.documents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),_vm._l((item.documents),function(item,i){return _c('a',{key:i,attrs:{"link":"","href":_vm.docURI}},[_vm._v("("+_vm._s(i + 1)+") "+_vm._s(item.name)+" , ")])})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 0)?_c('p',{staticClass:"success--text my-auto"},[_vm._v("Available")]):_c('p',{staticClass:"grey--text"},[_vm._v("Reserved")])]}},{key:"item.tender_no",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"primary--text text--darken-1 text-decoration-underline",on:{"click":function($event){$event.stopPropagation();return _vm.tenderPreview(item)}}},[_vm._v(_vm._s(item.tender_no))])]}},{key:"item.reserve",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"info","small":"","dark":""},on:{"click":function($event){return _vm.reserveItem(item)}}},[_vm._v(" Reserve "),_c('v-icon',[_vm._v("mdi-door")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"grey","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"item.bidders",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":0,"rounded":"","outlined":"","small":"","color":"success darken-1"}},[_vm._v(" "+_vm._s(item.clarifications)+" ")])]}},{key:"item.clarifications",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"info--text text--darken-1 text-decoration-underline",on:{"click":_vm.viewClarification}},[_c('v-badge',{attrs:{"color":"info","content":item.clarifications_answered + '/' + item.clarifications}},[_c('v-icon',[_vm._v("mdi-message-alert")]),_vm._v(" View ")],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }