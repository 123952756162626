<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-col cols="12" sm="10" class="mt-5">
            <v-toolbar-title
              class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
            >
              <v-icon class="mr-2 mt-n1" color="error"
                >mdi-domain</v-icon
              >Business Types
            </v-toolbar-title>
            <p class="grey--text ml-8 mt-n2">List of all business types</p>
            <v-divider class="mx-4" inset vertical></v-divider>
          </v-col>

          <v-col cols="12" sm="2" align="right">
            <v-dialog v-model="dialog" max-width="550px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus-thick</v-icon> New
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title>
                    <span class="headline error--text text--darken-1">{{
                      formTitle
                    }}</span>
                  </v-card-title>
                    <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" dense>
                          <v-text-field
                            v-model="editedItem.title"
                            label="Title"
                            :rules="requiredRules"
                            color="grey"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="my-n5">
                          <v-textarea
                            v-model="editedItem.description"
                            outlined
                            name="input-7-4"
                            label="Description"
                            color="grey"
                            dense
                            :rules="requiredRules"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                   <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                   <v-btn
                      color="error"
                      class="text-capitalize mx-1"
                      dark
                      @click="close"
                    >
                      Cancel <v-icon small>mdi-cancel</v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="text-capitalize mx-1"
                      :loading="loading"
                      @click="save"
                    >
                      Submit <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-card-actions>

                </v-form>
              </v-card>
            </v-dialog>
          </v-col>
          <v-spacer></v-spacer>
        </v-toolbar>
                 <v-progress-linear
                  indeterminate
                  color="primary"
                  v-if="loading"
                ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.closing_date="{ item }">
        {{ formatDateDefault(item.closing_date) }}
      </template>
      <template v-slot:item.documents="{ item }">
        {{ item.name }}
        <a
          v-for="(item, i) in item.documents"
          :key="i"
          link
          v-bind:href="docURI"
          >({{ i + 1 }}) {{ item.name }} ,
        </a>
      </template>
      <template v-slot:item.status="{ item }">
        <p v-if="item.status == 0" class="success--text my-auto">Available</p>
        <p v-else class="grey--text">Reserved</p>
      </template>
      <template v-slot:item.tender_no="{ item }">
        <a
          @click.stop="tenderPreview(item)"
          class="primary--text text--darken-1 text-decoration-underline"
          >{{ item.tender_no }}</a
        >
      </template>

      <template v-slot:item.reserve="{ item }">
        <div class="my-1">
          <v-btn
            color="info"
            class="mx-1"
            small
            dark
            @click="reserveItem(item)"
          >
            Reserve
            <v-icon>mdi-door</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="my-1">
          <v-btn
            color="grey"
            class="mx-1"
            fab
            x-small
            dark
            @click="editItem(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            color="error"
            class="mx-1"
            fab
            x-small
            dark
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.bidders="{ item }">
        <v-btn
          class="mx-2"
          :elevation="0"
          rounded
          outlined
          small
          color="success darken-1"
        >
          {{ item.clarifications }}
        </v-btn>
      </template>

      <template v-slot:item.clarifications="{ item }">
        <a
          @click="viewClarification"
          class="info--text text--darken-1 text-decoration-underline"
        >
          <v-badge
            color="info"
            :content="item.clarifications_answered + '/' + item.clarifications"
          >
            <v-icon>mdi-message-alert</v-icon> View
          </v-badge>
        </a>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import BusinessTypeService from "../../services/business_type.service";

export default {
  components: {},
  data: () => ({
    name: "BusinessTypes",
    loading: true,
    items: [],
    editedIndex: -1,
    editedItem: {
      title: "",
      description: "",
    },
    defaultItem: {
      title: "",
      description: "",
    },
    remember: 0,
    timeout: 2000,
    dialog: false,
    valid: true,
    requiredRules: [
      (v) => !!v || "Field is required",
      (v) => (v && v.length >= 3) || "Min 3 characters",
    ],
    selectRules: [(v) => !!v || "Select is required"],
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return BusinessTypeService.index().then(
        (response) => {
          if (response.status==200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        BusinessTypeService.delete(item).then(
          (response) => {
            if (response.status==200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            if (error.response.status == 401) {
              this.handleLogout();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          BusinessTypeService.update(this.editedItem).then(
            (response) => {
              if (response.status==200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          // create
          BusinessTypeService.store(this.editedItem).then(
            (response) => {
              if (response.status==200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>